import * as notificationService from "./notifications";
import * as profileService from "./profile";
import {mocks, objects, strings} from "@atttomyx/shared-utils";
import {iaps as iapUtils, mobile} from "@atttomyx/react-utils";
import {constants as games} from "@atttomyx/react-games";
import {parseChallengeId} from "../utils/deepLinks";
import {containsNoBannerAds, isNoBannerAds} from "../utils/iaps";
import {OBSERVER_APP} from "../constants";

const logFailure = (err) => console.log(err);

const removeMessageObservers = () => {
    mobile.removeMessagingTokenObserver(OBSERVER_APP);
    mobile.removeDeepLinkObserver(OBSERVER_APP);
    mobile.removeIapsObserver(OBSERVER_APP);
    mobile.removeIapObserver(OBSERVER_APP);
};

export const initMessageObservers = (snackbar, history, bootstrap, setJoinId, iaps, setIaps) => {
    removeMessageObservers();

    mobile.addMessagingTokenObserver(OBSERVER_APP, (messagingToken) => {
        if (strings.isNotBlank(messagingToken)) {
            syncMessagingToken(bootstrap, messagingToken);
        }
    });

    mobile.addDeepLinkObserver(OBSERVER_APP, (url) => {
        const joinId = parseChallengeId(url);

        if (joinId) {
            setJoinId(joinId);
        }
    });

    mobile.addIapsObserver(OBSERVER_APP, (iaps) => setIaps(iapUtils.sanitizeIaps(iaps)));

    mobile.addIapObserver(OBSERVER_APP, (iap) => {
        if (!iap.success) {
            snackbar.warn("Unable to complete purchase");

        } else if (isNoBannerAds(iap.itemId)) {
            buyNoBannerAds(snackbar, bootstrap.user);
        }
    });
};

const syncMessagingToken = (bootstrap, messagingToken) => {
    if (strings.differ(bootstrap.user.notifications.messagingToken, messagingToken)) {
        const modified = objects.deepCopy(bootstrap.user.notifications);

        modified.messagingToken = messagingToken;

        notificationService.savePreferences(modified, bootstrap.syncNotifications, logFailure);
    }
};

const buyNoBannerAds = (snackbar, bootstrap) => {
    const modified = objects.deepCopy(bootstrap.user.settings);

    modified.ads = games.ADS_NONE;

    const profile = {
        imageUrl: bootstrap.user.imageUrl,
        firstName: bootstrap.user.firstName,
        lastName: bootstrap.user.lastName,
        alias: bootstrap.user.alias,
        email: bootstrap.user.email,
        phone: bootstrap.user.phone,
        settings: modified,
    };

    const success = (saved) => {
        snackbar.setSuccess("Purchase complete");
        bootstrap.syncProfile(saved);
    }

    const failure = (err) => snackbar.setError("Unable to complete purchase");

    profileService.saveProfile(profile, success, failure);
};

export const restorePurchases = (bootstrap, ownedIds, success, failure) => {
    const profile = {
        imageUrl: bootstrap.user.imageUrl,
        firstName: bootstrap.user.firstName,
        lastName: bootstrap.user.lastName,
        alias: bootstrap.user.alias,
        email: bootstrap.user.email,
        phone: bootstrap.user.phone,
        settings: bootstrap.user.settings,
    };

    if (containsNoBannerAds(ownedIds) && bootstrap.user.settings.ads !== games.ADS_NONE) {
        const modified = objects.deepCopy(bootstrap.user.settings);

        modified.ads = games.ADS_NONE;
        profile.settings = modified;

        const _success = (saved) => {
            bootstrap.syncProfile(saved);
            success();
        }

        profileService.saveProfile(profile, _success, failure);

    } else {
        mocks.delayedInvoke(() => success(profile), 1000);
    }
};

import {objects, sorting} from "@atttomyx/shared-utils";

export const sortByGuesses = (a, b) => {
    const guessesA = a.metrics ? objects.defaultIfNullOrUndefined(a.metrics.guesses, 999999) : 999999;
    const guessesB = b.metrics ? objects.defaultIfNullOrUndefined(b.metrics.guesses, 999999) : 999999;

    let ret = 0;

    if (guessesA > guessesB) {
        ret = 1;

    } else if (guessesA < guessesB) {
        ret = -1;

    } else  {
        ret = sorting.sortByCreatedDesc(a, b);
    }

    return ret;
};

import axios from 'axios';
import {syncCachedUsers_leaderBoard} from "./cache";

export const loadLeaderBoards = (success, failure) => {
    const url = "/api/v1/game/leaderBoards";

    axios.get(url)
        .then(response => {
            const json = response.data;

            syncCachedUsers_leaderBoard(json.easy);
            syncCachedUsers_leaderBoard(json.medium);
            syncCachedUsers_leaderBoard(json.hard);
            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const loadStats = (success, failure) => {
    const url = "/api/v1/game/stats";

    axios.get(url)
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const createGame = (difficulty, success, failure) => {
    const url = "/api/v1/game/?difficulty=" + difficulty;

    axios.get(url)
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const completeGame = (difficulty, guesses, correct, success, failure) => {
    const url = "/api/v1/game/complete";

    axios.post(url, {
        difficulty: difficulty,
        metrics: {
            guesses: guesses,
            correct: correct,
        },
    })
        .then(response => {
            success();
        })
        .catch(err => {
            failure(err);
        });
};

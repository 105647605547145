import {PATH_CHALLENGE, WEB_BASE_URL} from "../constants";

export const toJoinUrl = (challengeId) => WEB_BASE_URL + PATH_CHALLENGE + challengeId;

export const parseChallengeId = (joinUrl) => {
    let challengeId = null;

    if (joinUrl) {
        const index = joinUrl.indexOf(PATH_CHALLENGE);

        if (index > -1) {
            challengeId = joinUrl.substring(index + PATH_CHALLENGE.length);
        }
    }

    return challengeId;
};

import axios from 'axios';
import {sanitizeProfile} from "../utils/users";
import {CLOUDINARY_ID} from "../constants";

export const loadProfile = (success, failure) => {
    axios.get("/api/v1/profile/")
        .then(response => {
            const profile = response.data;

            sanitizeProfile(profile);
            success(profile);
        })
        .catch(err => {
            failure(err);
        });
};

export const saveProfile = (profile, success, failure) => {
    axios.put("/api/v1/profile/", {
        firstName: profile.firstName,
        lastName: profile.lastName,
        alias: profile.alias,
        email: profile.email,
        phone: profile.phone,
        imageUrl: profile.imageUrl,
        settings: profile.settings,
    })
        .then(response => {
            const saved = response.data;

            sanitizeProfile(saved);
            success(saved);
        })
        .catch(err => {
            failure(err);
        });
};

export const uploadImage = (userId, image, success, failure) => {
    const url = `https://api.cloudinary.com/v1_1/${CLOUDINARY_ID}/upload`;

    const data = new FormData();
    data.append("upload_preset", "user_image");
    data.append("file", image);
    data.append("tags", userId);

    // need to skip the request interceptors (don't want to include the JWT)
    const instance = axios.create();

    instance.post(url, data, {
        headers: {
            "X-Requested-With": "XMLHttpRequest",
        },
    })
        .then(response => {
            success(response.data.secure_url);
        })
        .catch(err => {
            failure(err);
        });
};

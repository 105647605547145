import {objects} from "@atttomyx/shared-utils";
import {constants as games} from "@atttomyx/react-games";
import {DEFAULT_BEST} from "../constants";

export const toScore = (metrics) => metrics.guesses;

export const getBest = (stats, difficulty) => {
    let best;

    if (stats) {
        switch (difficulty) {
            case games.EASY:
                if (stats.easy && stats.easy.best) {
                    best = stats.easy.best.guesses;
                }
                break;
            case games.MEDIUM:
                if (stats.medium && stats.medium.best) {
                    best = stats.medium.best.guesses;
                }
                break;
            case games.HARD:
                if (stats.hard && stats.hard.best) {
                    best = stats.hard.best.guesses;
                }
                break;
            default:
                console.log("unsupported: " + difficulty);
        }
    }

    return objects.defaultIfNullOrUndefined(best, DEFAULT_BEST);
};

export const getStars = (guesses, correct) => {
    let stars = 0;

    if (correct) {
        if (guesses < 4) {
            stars = 3;

        } else if (guesses < 7) {
            stars = 2;

        } else {
            stars = 1;
        }
    }

    return stars;
};

import {themes as themeUtils} from "@atttomyx/react-utils";
import {objects, users} from "@atttomyx/shared-utils";
import {constants as games} from "@atttomyx/react-games";
import {themes} from "@atttomyx/shared-constants";

export const sanitizeUser = (user) => {
    user.roles = users.normalizeRoles(user.roles);
    user.settings = sanitizeSettings(user.settings);
};

export const sanitizeProfile = (profile) => {
    profile.settings = sanitizeSettings(profile.settings);
};

const sanitizeSettings = (settings) => {
    const sanitized = settings || {};
    const theme = sanitized.theme === themes.LIGHT ? themes.LIGHT : themes.DARK;

    sanitized.theme = theme;
    sanitized.primaryColor = objects.defaultIfNullOrUndefined(sanitized.primaryColor, themeUtils.defaultPrimary(theme));
    sanitized.secondaryColor = objects.defaultIfNullOrUndefined(sanitized.secondaryColor, themeUtils.defaultSecondary(theme));
    sanitized.backgroundColor = objects.defaultIfNullOrUndefined(sanitized.backgroundColor, themeUtils.defaultBackground(theme));
    sanitized.difficulty = objects.defaultIfNullOrUndefined(sanitized.difficulty, games.EASY);
    sanitized.ads = objects.defaultIfNullOrUndefined(sanitized.ads, games.ADS_BANNER);

    return sanitized;
};

export const settingsToBranding = (settings) => {
    const theme = settings.theme;

    return {
        primary: objects.defaultIfNullOrUndefined(settings.primaryColor, themeUtils.defaultPrimary(theme)),
        secondary: objects.defaultIfNullOrUndefined(settings.secondaryColor, themeUtils.defaultSecondary(theme)),
        background: objects.defaultIfNullOrUndefined(settings.backgroundColor, themeUtils.defaultBackground(theme)),
    }
};

import axios from 'axios';
import axiosRetry from 'axios-retry';
import {storage} from "@atttomyx/react-utils";
import {strings, users} from "@atttomyx/shared-utils";
import {time} from "@atttomyx/shared-constants";
import {API_BASE_URL, KEY_AUTH_TOKEN} from "../constants";

const local = storage.getLocal();

let source = axios.CancelToken.source();

axios.defaults.timeout = time.SECONDS_IN_MINUTE * time.MILLISECONDS_IN_SECOND;
axios.defaults.baseURL = API_BASE_URL;

axiosRetry(axios, {
    retries: 3,
    retryDelay: axiosRetry.exponentialDelay,
    retryCondition: axiosRetry.isSafeRequestError,
});

const onRequestSuccess = config => {
    const token = local.getStr(KEY_AUTH_TOKEN);

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    config.cancelToken = source.token;

    return config;
};

export const setupAxiosInterceptors = onUnauthenticated => {
    const onResponseError = err => {
        const status = err.status || (err.response ? err.response.status : 0);
        if (status === 403 || status === 401) {
            onUnauthenticated();
        }
        return Promise.reject(err);
    };

    const onResponseSuccess = response => response;

    axios.interceptors.request.use(onRequestSuccess);
    axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export const cancelAllRequests = (reason) => {
    source.cancel(reason);
    source = axios.CancelToken.source();
};

export const getLoggedInUser = (success, failure) => {
    axios.get("/api/v1/auth/user")
    .then(response => {
        const json = response.data;

        json.roles = users.normalizeRoles(json.roles);

        success(json);
    })
    .catch(err => {
        failure(err);
    });
};

export const findAccounts = (success, failure) => {
    success([]);    // noop
};

export const simpleLogin = (installationId, success, failure) => {
    // need to skip the request interceptors (don't want to include the JWT)
    const instance = axios.create();

    instance.post("/api/v1/auth/simple", {
        installationId: installationId,
    })
        .then(response => {
            const bearerToken = response.headers.authorization;
            const json = response.data;

            if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
                const jwt = bearerToken.slice(7, bearerToken.length);

                clearAuthToken();
                storeAuthToken(jwt);
            }

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const requestLinkCode = (alias, success, failure) => {
    // need to skip the request interceptors (don't want to redirect to login on 401)
    const instance = axios.create();
    instance.interceptors.request.use(onRequestSuccess);

    instance.post("/api/v1/auth/requestLinkCode", {
        alias: alias,
    })
        .then(response => {
            success();
        })
        .catch(err => {
            failure(err);
        });
};

export const submitLinkCode = (alias, code, success, failure) => {
    // need to skip the request interceptors (don't want to redirect to login on 401)
    const instance = axios.create();
    instance.interceptors.request.use(onRequestSuccess);

    instance.post("/api/v1/auth/submitLinkCode", {
        alias: alias,
        code: code,
    })
        .then(response => {
            const bearerToken = response.headers.authorization;
            const json = response.data;

            if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
                const jwt = bearerToken.slice(7, bearerToken.length);

                clearAuthToken();
                storeAuthToken(jwt);
            }

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const loggedIn = () => {
    const jwt = local.getStr(KEY_AUTH_TOKEN);

    return strings.isNotBlank(jwt);
};

const storeAuthToken = (jwt) => {
    local.setStr(KEY_AUTH_TOKEN, jwt);
};

export const clearAuthToken = () => {
    local.clear(KEY_AUTH_TOKEN);
};

import {strings} from "@atttomyx/shared-utils";

export const redact = (sentence, word) => {
    const words = sentence.split(" ");
    const redactedSentence = [];
    const lower1 = word.toLowerCase();
    const length1 = lower1.length;
    const redacted1 = getRedacted(length1);

    words.forEach(candidate => {
        const length2 = candidate.length;
        const lower2 = candidate.toLowerCase();
        const redacted2 = getRedacted(length2);

        if (lower1 === lower2) {
            redactedSentence.push(redacted1);

        } else if (length1 > length2 && length2 > 2 && strings.contains(lower1, lower2)) {
            redactedSentence.push(redacted2);

        } else if (length2 > length1 && strings.contains(lower2, lower1)) {
            redactedSentence.push(redacted2);

        } else {
            redactedSentence.push(candidate);
        }
    });

    return redactedSentence.join(" ");
};

const getRedacted = (length) => {
    let redacted = "";

    for (let i = 0; i < length; i++) {
        redacted += "_";
    }

    return redacted;
};

import React from "react";
import {Divider} from "@mui/material";
import {GotoButton} from "@atttomyx/react-components";
import {PAGE_HOME} from "../../constants";

const BackToHome = (props) => {
    const { home } = props;

    return <>
        <Divider/>
        <GotoButton page={home || PAGE_HOME} label="Home"/>
    </>
};

export default BackToHome;

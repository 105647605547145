import {colors} from "@atttomyx/shared-utils";

export const dark = (branding) => {
    return {
        MuiTypography: {
            styleOverrides: {
                root: {
                    "&.outlined": {
                        border: "1px solid",
                        borderRadius: "4px",
                        fontWeight: "lighter",
                        fontSize: "24px",
                        lineHeight: "30px",
                        width: "32px",
                        height: "32px",
                        backgroundColor: colors.makeBrighter(branding.background, 32),
                    }
                },
            },
        },
    }
};

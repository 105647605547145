import React from "react";
import {Dialog, DialogContent, List, ListItem} from "@mui/material";
import {ClosableDialogTitle} from "@atttomyx/react-components";
import "./helpDialog.css";

const HelpDialog = (props) => {
    const { open, onCancel } = props;

    return <Dialog
        open={open}
        aria-labelledby="help-dialog"
    >
        <ClosableDialogTitle onClose={onCancel}>
            How to play
        </ClosableDialogTitle>
        <DialogContent className="help-dialog">
            <List dense={true} className="i">
                <ListItem>
                    Make a guess (does not have to be a word)
                </ListItem>
                <ListItem>
                    For each letter, the game will tell you:
                    <List dense={true} className="ii">
                        <ListItem className="wrong">
                            letter is not in the word
                        </ListItem>
                        <ListItem className="partial">
                            letter is in the word, but at another spot
                        </ListItem>
                        <ListItem className="correct">
                            letter is in the word at that spot
                        </ListItem>
                    </List>
                </ListItem>
                <ListItem>
                    Using those clues, make another guess
                </ListItem>
                <ListItem>
                    Keep guessing until you reveal the word
                </ListItem>
            </List>
        </DialogContent>
    </Dialog>
}

export default HelpDialog;

import axios from 'axios';
import {sanitizeUser} from "../utils/users";

export const findByAlias = (alias, success, failure) => {
    const url = "/api/v1/user/find?alias=" + alias;

    axios.get(url)
        .then(response => {
            const json = response.data;
            const user = json.user;

            if (user) {
                sanitizeUser(user);
            }

            success(user);
        })
        .catch(err => {
            failure(err);
        });
};

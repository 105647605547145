import {ACCOUNT_ID} from "../constants";

export const loadMyAccount = (success, failure) => {
    success({
        id: ACCOUNT_ID,
    });
};

export const saveMyAccount = (account, success, failure) => {
    success({});
};

export const uploadImage = (accountId, logo, success, failure) => {
    success(null);
};

export const APP_NAME = "Words";
export const APP_TAG_LINE = "Use logic to guess hidden words";
export const APP_ID_APPLE = "1547098934";
export const APP_ID_GOOGLE = "io.milesoft.words";

// export const API_BASE_URL = "https://words-app-s25pzcrexa-uc.a.run.app";    // sand
export const API_BASE_URL = "https://words-app-pycr36o6wq-uc.a.run.app";    // prod
export const WEB_BASE_URL = "https://words.milesoft.app";

export const PATH_DEEP_LINK = "/deep-link";
export const PATH_CHALLENGE = PATH_DEEP_LINK + "/challenge/";

export const ACCOUNT_ID = "MIgK0o0h9mmJ6RT7gMi6";

export const CLOUDINARY_ID = "cuteandfuzzy";

export const FIREBASE_CONFIG = {
    apiKey: "AIzaSyA9Dj9SL_KXAQw4DfB-66XmJvyAUytOMdo",
    authDomain: "cf-notifier.firebaseapp.com",
    projectId: "cf-notifier",
    storageBucket: "cf-notifier.appspot.com",
    messagingSenderId: "741330969895",
    appId: "1:741330969895:web:795f316c4aca845967f502",
    measurementId: "G-7YK8FMDCTM"
};

export const NAMESPACE = "words";
export const KEY_VERSION = "version";
export const KEY_AUTH_TOKEN = "auth_token";
export const KEY_USERS = "challengers";
export const KEY_LEADER_BOARDS = "leaderboards";
export const KEY_GAME = "game";
export const KEY_MOVES = "moves";
export const KEY_HINTS = "hints";
export const KEY_RESULT = "result";

export const CHALLENGE_ID_NONE = "none";

export const OBSERVER_APP = "app";
export const OBSERVER_GAME = "game";

export const EXPLANATION_EASY = "3 or 4-letter words, vowels are given";
export const EXPLANATION_MEDIUM = "5 or 6-letter words, letters do not repeat";
export const EXPLANATION_HARD = "5 or 6-letter words, letters repeat";

export const ANIMAL_CANARY = "Canary";
export const ANIMAL_CAT = "Cat";
export const ANIMAL_CHINCHILLA = "Chinchilla";
export const ANIMAL_COW = "Cow";
export const ANIMAL_CRAB = "Crab";
export const ANIMAL_DOG = "Dog";
export const ANIMAL_EAGLE = "Eagle";
export const ANIMAL_FERRET = "Ferret";
export const ANIMAL_FISH = "Fish";
export const ANIMAL_GERBIL = "Gerbil";
export const ANIMAL_GUINEA_PIG = "GuineaPig";
export const ANIMAL_HAMSTER = "Hamster";
export const ANIMAL_HORSE = "Horse";
export const ANIMAL_LIZARD = "Lizard";
export const ANIMAL_MOUSE = "Mouse";
export const ANIMAL_PIG = "Pig";
export const ANIMAL_RABBIT = "Rabbit";
export const ANIMAL_SNAKE = "Snake";
export const ANIMAL_SPIDER = "Spider";
export const ANIMAL_TURTLE = "Turtle";

export const ANIMALS = [
    // ANIMAL_CANARY,
    ANIMAL_CAT,
    // ANIMAL_CHINCHILLA,
    // ANIMAL_COW,
    // ANIMAL_CRAB,
    ANIMAL_DOG,
    // ANIMAL_EAGLE,
    // ANIMAL_FERRET,
    // ANIMAL_FISH,
    // ANIMAL_GERBIL,
    ANIMAL_GUINEA_PIG,
    // ANIMAL_HAMSTER,
    // ANIMAL_HORSE,
    // ANIMAL_LIZARD,
    // ANIMAL_MOUSE,
    // ANIMAL_PIG,
    ANIMAL_RABBIT,
    // ANIMAL_SNAKE,
    // ANIMAL_SPIDER,
    ANIMAL_TURTLE,
];

export const STOP_SHOWING_HELP_AFTER = 1;
export const REQUEST_REVIEW_AFTER = 10;

export const DEFAULT_BEST = 1000;

export const REGEX_LETTERS = /^[A-Za-z]+$/;

export const IAP_IOS_NO_BANNER_ADS = "noBannerAds";
export const IAP_IOS_GEMS_1 = "gems1";
export const IAP_IOS_GEMS_2 = "gems2";
export const IAP_IOS_GEMS_3 = "gems3";
export const IAP_IOS_GEMS_4 = "gems4";
export const IAP_IOS_GEMS_5 = "gems5";
export const IAP_IOS_GEMS_6 = "gems6";

export const IAP_ANDROID_NO_BANNER_ADS = "no_banner_ads";
export const IAP_ANDROID_GEMS_1 = "gems_i";
export const IAP_ANDROID_GEMS_2 = "gems_ii";
export const IAP_ANDROID_GEMS_3 = "gems_iii";
export const IAP_ANDROID_GEMS_4 = "gems_iv";
export const IAP_ANDROID_GEMS_5 = "gems_v";
export const IAP_ANDROID_GEMS_6 = "gems_vi";

// for testing the store
export const IAPS_IOS = {
    items: [
        {
            id: IAP_IOS_NO_BANNER_ADS,
            title: "Permanently remove banner ads",
            price: "$3.99",
        },
        {
            id: IAP_IOS_GEMS_1,
            title: "Pouch of gems (30)",
            price: "$0.99",
        },
        {
            id: IAP_IOS_GEMS_2,
            title: "Bowl of gems (180)",
            price: "$4.99",
        },
        {
            id: IAP_IOS_GEMS_3,
            title: "Bucket of gems (432)",
            price: "$9.99",
        },
        {
            id: IAP_IOS_GEMS_4,
            title: "Crate of gems (1035)",
            price: "$19.99",
        },
        {
            id: IAP_IOS_GEMS_5,
            title: "Vault of gems (3105)",
            price: "$49.99",
        },
        {
            id: IAP_IOS_GEMS_6,
            title: "Boatload of gems (7452)",
            price: "$99.99",
        },
    ],
};

// standard private pages
export const PAGE_USERNAME = "/username";
export const PAGE_PROFILE = "/profile";
export const PAGE_OPTIONS = "/options";
export const PAGE_ABOUT = "/about";
export const PAGE_STORE = "/store";

// custom private pages
export const PAGE_DASHBOARD = "/dashboard";
export const PAGE_LEADERBOARDS = "/leaderboards";
export const PAGE_CHALLENGE = "/challenge";
export const PAGE_GAME = "/game";

export const PAGE_HOME = PAGE_DASHBOARD;

export const PAGES_EXACT = [
    PAGE_USERNAME,
    PAGE_PROFILE,
    PAGE_OPTIONS,
    PAGE_ABOUT,
    PAGE_STORE,
    PAGE_DASHBOARD,
    PAGE_LEADERBOARDS,
    PAGE_CHALLENGE,
];

export const PAGES_STARTS_WITH = [
    PAGE_GAME,
];

import {colors} from "@atttomyx/shared-utils";

export const light = (branding) => {
    return {
        MuiTypography: {
            styleOverrides: {
                root: {
                    "&.outlined": {
                        border: "2px solid",
                        borderRadius: "4px",
                        fontWeight: "normal",
                        fontSize: "24px",
                        lineHeight: "28px",
                        width: "32px",
                        height: "32px",
                        backgroundColor: colors.makeBrighter(branding.background, 36),
                    }
                },
            },
        },
    }
};
